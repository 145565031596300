import { render, staticRenderFns } from "./Layout.vue?vue&type=template&id=52a54fc2&"
import script from "./Layout.vue?vue&type=script&lang=ts&"
export * from "./Layout.vue?vue&type=script&lang=ts&"
import style0 from "./Layout.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QTable from 'quasar/src/components/table/QTable.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QHeader,QToolbar,QBtn,QSeparator,QBtnGroup,QMenu,QCard,QCardSection,QInput,QIcon,QList,QItem,QItemSection,QItemLabel,QSpace,QBtnDropdown,QDrawer,QImg,QScrollArea,QPageContainer,QPage,QTable});qInstall(component, 'directives', {ClosePopup});
