

















































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Layout extends Vue {
  @Prop()
  public enumListItems!: any[];

  public leftDrawerOpen = false;
  private filterTxt = "";
  private filteredLinks: any[] = [];
  private administrativeLinks = [
    {
      name: "ActionTypesList",
      label: "Service Types"
    },
    {
      name: "ActionsList",
      label: "Services"
    },
    {
      name: "CurrenciesList",
      label: "Currencies"
    },
    {
      name: "ActivitiesList",
      label: "Activities"
    },
    {
      name: "DonorsList",
      label: "Donors"
    },
    {
      name: "CommunicationTypeList",
      label: "Communication Types"
    },
    {
      name: "ResultFrameworkBreakdownList",
      label: "Framework Breakdowns"
    },
    {
      name: "ConsentFormList",
      label: "Consent Forms"
    },
    {
      name: "FollowUpList",
      label: "Follow Up"
    },
    {
      name: "CompaniesList",
      label: "Companies"
    }
  ];
  private versionInfo: any = null;
  @Watch("filterTxt")
  private filter() {
    this.filteredLinks = this.administrativeLinks.filter(
      s =>
        !this.filterTxt ||
        s.label.toLowerCase().indexOf(this.filterTxt.toLowerCase()) > -1
    );
  }

  private async logout() {
    window.localStorage.removeItem("redirect");
    await this.$auth.logout();
  }

  async mounted() {
    this.showReports();
    for (const item of this.enumListItems) {
      this.administrativeLinks.push({
        name: item.listRouteName,
        label: item.listTitle
      });
    }
    this.administrativeLinks.sort((a, b) => {
      if (a.label < b.label) return -1;
      if (a.label > b.label) return 1;
      return 0;
    });
    this.filteredLinks = [...this.administrativeLinks];
    this.versionInfo = await this.$service.getApiVersion();
  }
  showReports() {
    const perm = this.$auth.permissions.reports;

    const vals = Object.keys(perm).map(function (key) {
    return perm[key];
      }); 

    return vals.includes(true);
  }
  async getVersion() {
    this.versionInfo = await this.$service.getApiVersion();
  }
}
